@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
    color: #000000;
}

#menuToggle
{
  display: block;
  position: relative;
  top: 50px;
  left: 50px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0;
  z-index: 2;
  
  -webkit-touch-callout: none;
}

#menu
{
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}

.contBarra {
    width: 100vw;
}

.buttBarra {
    width: fill;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.barra {
    background-color: #333;
    height: 5vh;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
}

.barra ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.barra a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.barra li:hover {
    background-color: #777;
}

.active {
    background-color: #ffffff;
}

.ModalBoxZTL {
    height: 350px;
    width: 350px;
    background-color: rgb(153, 154, 207);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 7%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.divFormInserisci {
    width: 50vw;
    height: 50vh;
    left: 30%;  
    /* perchè non si sposta a sinistra */
}

.background {
background-image: 
radial-gradient(rgb(232, 232, 232) 26.4%, transparent 26.4%);
background-position: 4px 4px;
background-size: 8px 8px;
background-color: rgb(243, 244, 246);
}

@layer components {
  .table :where(th, td) {
    white-space: inherit;
  }
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.75);
}

/* colors for horizontal main menu bar */

#h-bar-menu:hover {
  background-color: #e2e8f0;
  color: #000000;
}

#h-bar-menu:active {
  background-color: #000000;
  border-color: #ffffff;
  border-width: 0.1rem;
  color: #ffffff;
}